<template>
  <section class="wrapper payments">
    <img
      v-for="(payment, i) in payments"
      :key="i"
      :src="require(`@/assets/img/payments/${payment}.svg`)"
      :alt="payment"
      class="payments-icon"
    >
  </section>
</template>

<script>
export default {
  name: 'Payments',
  data: () => ({
    payments: [
      'visa',
      'mastercard',
      'paypal',
      'trustly',
      'muchbetter',
      'skrill',
    ],
  }),
};
</script>

<style lang="scss">
.payments {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  &.wrapper {
    padding: 16px;

    @media (min-width: $screen-m) {
      padding: 30px 16px;
    }
  }

  &-icon {
    width: 96px;

    @media (min-width: $screen-m) {
      width: initial;
    }
  }
}
</style>
