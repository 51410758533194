<template>
  <div class="card">
    <picture>
      <source
        type="image/webp"
        :srcset="
          require(`@/assets/img/games/webp/${src}.webp`) +
            ' 1x, ' +
            require(`@/assets/img/games/webp/${src}@2x.webp`) +
            '2x'
        "
        alt="card image"
        class="card-img"
      >
      <img
        :src="require(`@/assets/img/games/png/${src}.png`)"
        :srcset="
          require(`@/assets/img/games/png/${src}.png`) +
            ' 1x, ' +
            require(`@/assets/img/games/png/${src}@2x.png`) +
            '2x'
        "
        alt="card image"
        class="card-img"
      >
    </picture>
    <h3
      class="card-title"
      v-html="title"
    />
    <p
      class="card-text"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: 'GamesCard',
  props: {
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.card {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 288px;
  padding: 44px 23px 40px;
  border-radius: 6px;
  text-align: center;
  line-height: 1.4;
  border: 1px solid var(--color-grey-dark);

  @media (min-width: $screen-m) {
    justify-content: space-between;
    max-width: 390px;
    height: 530px;
    padding: 52px 35px 70px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 6px;
  }

  &:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--color-body);
  }

  &:after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    z-index: -2;
    background: var(--color-outlines-inner-border);
  }

  &-img {
    width: 184px;

    @media (min-width: $screen-m) {
      width: initial;
    }
  }

  &-title {
    margin-bottom: 14px;
    font-size: 18px;
    color: var(--color-text-white);

    @media (min-width: $screen-m) {
      margin-bottom: 0;
      font-size: 24px;
    }
  }
  &-text {
    font-size: 12px;

    @media (min-width: $screen-m) {
      font-size: 16px;
    }
  }
}
</style>
