var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('picture',[_c('source',{staticClass:"card-img",attrs:{"type":"image/webp","srcset":require(`@/assets/img/games/webp/${_vm.src}.webp`) +
          ' 1x, ' +
          require(`@/assets/img/games/webp/${_vm.src}@2x.webp`) +
          '2x',"alt":"card image"}}),_c('img',{staticClass:"card-img",attrs:{"src":require(`@/assets/img/games/png/${_vm.src}.png`),"srcset":require(`@/assets/img/games/png/${_vm.src}.png`) +
          ' 1x, ' +
          require(`@/assets/img/games/png/${_vm.src}@2x.png`) +
          '2x',"alt":"card image"}})]),_c('h3',{staticClass:"card-title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(_vm.text)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }